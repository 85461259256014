.clock {
  font-size: 0.6rem;
}

@media (min-width: 600px) {
  .clock {
    font-size: 0.8rem;
  }
}

.clock .blink {
  animation: blink 1s step-start infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
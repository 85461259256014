.card {
  width: 250px;
  border: 1px solid black;
  margin: 1rem;
  height: 450px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.card img {
  width: 100%;
  height: auto;
  position: relative;
}

.card__price {
  font-size: 0.8rem;
}

.card__description {
  font-size: 0.6rem;
}

.card__title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.card__footer {
  background-color: white;
  padding: 0.5rem;
}

.card__footer__action {
  display: flex;
  align-items: center;
}

.card__footer__action>.card-action--added {
  margin-left: 0.8rem;
  font-weight: bold;
  color: green;
}

.card__footer__action>.card-action--oos {
  margin-left: 0.8rem;
  font-weight: bold;
  color: red;
}
.panel {
  position: absolute;

  border: 1px solid black;
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  background-color: white;
}

.panel__header {
  font-family: 'Courier New', Courier, monospace;
  padding: 0.25rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  max-height: 30px;
  background-color: rgb(224, 224, 224);
  cursor: grab;
}

.panel__header__drag-area {
  display: flex;
  width: 100%;
}


.panel__header:active {
  cursor: grabbing;
}

.panel__header__title {
  font-style: italic;
}

.panel__header__spacer {
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin: 0;
  padding: 3px;
  flex-grow: 100;
}

.panel__header__spacer>li {
  width: 100%;
  height: 1px;
  margin: 1px;
  background-color: black;
}

.panel__content--resizeable {
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  overflow: scroll;
}

.panel__content--resizeable::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.panel__content--resizeable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.panel__header__controls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.panel__header__controls>div {
  width: 20px;
  height: 20px;
  border: 1px solid black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.3rem;
}

.panel__controls--close {
  color: black;
  background-color: rgba(214, 163, 193, 0.5)
}

.panel__controls--expand {
  color: black;
  background-color: rgba(121, 208, 237, 0.5)
}

.panel__resize-handle {
  position: absolute;
  box-sizing: content-box;
  width: 3px;
  height: 3px;
  background-color: white;
  border: 5px solid gray;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  bottom: 2px;
  right: 2px;
  cursor: se-resize;
}

.panel__resize-handle:hover {
  border-color: red;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
}

.panel__footer {
  position: absolute;
  bottom: 0;
  height: 10px;
  width: 100%;
}

.panel__helper--resize {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.6rem;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: default;
  transform-origin: right;
  transform: rotate(90deg) translateY(-10px);
}

.panel__helper--drag {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.6rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: default;
  transform-origin: right;
  transform: rotate(90deg) translate(100%, -10px);
}

/**
*
* PANEL SPECIFIC
*/

.panel-name {
  cursor: pointer;
}

.panel-name:hover {
  text-decoration: line-through;
}

.panel--focused {
  position: relative;
  z-index: 1100;
}
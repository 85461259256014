.contact-form{
  display: flex;
  padding: 1rem;
  flex-flow: column nowrap;
  max-width: 500px;
}
.contact-form label {
  margin-top: 12px;
}

.contact-form input,
.contact-form textarea {
  font-size: 1.2rem;
  line-height: 1.6rem;
  width: 100%;
  border: 1px solid black;
  padding: 2px;
}

.contact-form .recaptcha {
  margin-top: 1rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  background-color: #e1e1e0;
  background-image: url("./grid.png");
  background-repeat: repeat;
}

nav {
  background-color: rgba(255, 255, 255, 0.95);
}

#wrapper {
  position: relative;
  width: 100%;
}

a,
a:visited {
  color: blue;
  text-decoration: none;
}

a:hover {
  text-decoration: line-through;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, 'Times New Roman', Times, serif;

}


/* HELPERS */

.intro .title {
  font-size: 2.5rem;
  margin-bottom: 0;
  text-decoration: underline;
  margin-top: 1rem;

}

.intro .subtitle {
  margin-top: 0;
  font-size: 3.4rem;
}

.subtitle {
  font-size: 1.5rem;
}

p {
  max-width: 600px;
}

.highlight {
  background-color: yellow;
}

blockquote {
  border-left: 3px solid grey;
  padding-left: 0.5rem;
  margin: 0.5rem 0.5rem;
}

.author:before {
  content: '-';
}

.form {
  max-width: 600px;
}

.row {
  max-width: 1000px;
  display: flex;
  flex-flow: row;
}

.row .item {
  padding: 0.5rem;
  flex: 1 1;
}

.col {
  display: flex;
  flex-flow: column;

}

.col .item {
  display: flex;
  flex-flow: row;
  padding: 0.5rem;
}

.img.xs {
  width: 200px;
  height: auto;
}

.img.small {
  width: 300px;
  height: auto;
}

.img.medium {
  width: 600px;
  height: auto;
}

.img.large {
  width: 900px;
  height: auto;
}

.img.stretch {
  width: 100%;
}

.small {
  font-size: 0.8rem;
}

.super-small {
  font-size: 0.5rem;
}

.bold {
  font-weight: bold;
}

@media (min-width: 900px) {
  nav {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .row {
    max-width: 1200px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}


/* DESKTOP */
.location {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.desktop {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 30px);
}


.toolbar {
  width: 100%;
  height: 30px;
  /* background-color: rgb(8, 12, 140); */
  background-color: #ffb7dea3;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.3rem;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
}

.toolbar__section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 1rem;
}

.toolbar__icon {
  width: 2rem;
  margin-right: 0.5rem;
}

.desktop__icon__bank {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.desktop__icon {
  width: 3rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  cursor: pointer;
  margin: 0.5rem 0.5rem;
}

.desktop__icon__image {
  width: 2.5rem;
}

.desktop__icon__text {
  font-size: 0.8rem;
  font-family: 'Courier New', Courier, monospace;
}


/* HOME */

.portrait {
  position: relative;
  width: 200px;
  height: 200px;
}

.portrait .cmyk {
  position: absolute;
  border-radius: 50%;
  width: 200px;
  mix-blend-mode: multiply;
  transition: margin-left 0.4s;
}

.portrait .cmyk.stretch.cmyk-m,
.portrait:hover .cmyk.cmyk-m {
  margin-left: 50px;
}

.portrait .cmyk.stretch.cmyk-y,
.portrait:hover .cmyk.cmyk-y {
  margin-left: 100px;
}

.portrait .cmyk.stretch.cmyk-k,
.portrait:hover .cmyk.cmyk-k {
  margin-left: 150px;
}

/* ABOUT */
.blurb {
  display: flex wrap;
}

.blurb__image {
  margin: 0.5rem;
  width: 200px;
  height: 175px;
}

.blurb__text {
  min-width: 300px;
}


/* BAM */
.btn {
  font-style: bold;
  font-size: 1rem;
  padding: 0.5em;
  cursor: pointer;
}

.btn-primary {
  background-color: blue;
  color: white;
}

.btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}



/* CODE */
pre {
  background-color: #D1D1D0;
  overflow: auto;
  font-family: 'Monaco', monospace;
  padding: 0 1em;
}



code {
  font-family: Monaco, monospace;
  font-size: 0.8rem;
  line-height: 100%;
  background-color: #eee;
  padding: 0.2em;
  letter-spacing: -0.05em;
  word-break: normal;
  border-radius: 5px;
}



pre code {
  border: none;
  background: none;
  line-height: 1em;
  letter-spacing: normal;
  word-break: break-all;
}

/**
 CONTACT FORM
*/



.modal {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  height: auto;
  transform: translate(-50%, -50%);
  background-color: rgb(250, 250, 250);
  padding: 1rem;
}

.cover-all {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3)
}